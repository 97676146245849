<template>
  <div class="page-region-list px-3 py-4">
    <div
      class="flex items-center py-3.5 px-2 mb-2 rounded-md bg-white"
      v-for="(item, index) in 20"
      :key="index"
    >
      <div class="text-sm font-bold flex-1">华南区域</div>
      <div class="btn-edit" @click="editRegion('edit', item)">编辑</div>
      <div class="btn-del ml-5">删除</div>
    </div>
    <div class="footer-btn" @click="editRegion('add')">新增</div>
  </div>
</template>
<script>
import { Button } from "vant";
export default {
  name: "RegioList",
  components: {
    [Button.name]: Button,
  },
  methods: {
    editRegion(status = "", data = "") {
      let region_data = {
        status: status,
        data: data,
      };
      this.$router.push({ path: "/region/edit", query: region_data });
    },
  },
};
</script>
<style scoped>
.page-region-list {
  min-height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 80px;
  box-sizing: border-box;
}
.btn-edit {
  border-radius: 5px;
  border: 1px solid #6b9bfb;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6a99f9;
  line-height: 1;
  padding: 3px 8px;
}
.btn-del {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  text-decoration: underline;
  color: #666666;
}
</style>
